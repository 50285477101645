import React, { useState } from 'react';
import axios from 'axios';
import ReactJson from 'react-json-view';

const App = () => {
    const [slotData, setSlotData] = useState(null);
    const [slotNumber, setSlotNumber] = useState('');

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://api.minimaleth.pics/block/${slotNumber}`);
            setSlotData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div>
            <h1>Ethereum Slot Explorer</h1>
            <input
                type="text"
                value={slotNumber}
                onChange={(e) => setSlotNumber(e.target.value)}
                placeholder="Enter Slot Number"
            />
            <button onClick={fetchData}>Fetch Slot Data</button>

            {slotData && (
                <ReactJson src={slotData} collapsed={true} />
            )}
        </div>
    );
};

export default App;
